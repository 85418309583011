import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Paper } from "@mui/material";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import { default as React, useEffect, useState } from "react";
import { BsArrowRepeat } from "react-icons/bs";
import { organization_id } from "../TotalView.js";
import uiText from "../context/translation.json";
import { formatDateToDatabaseDate } from "../utils/formatDate";
import { triggerSynchronizatioByDatesBackground } from "../views/documents/documentAPI.js";
import DateRangePicker from "./DateRangePicker/DateRangePicker";
// Define CheckBox with Label Component
const CheckBoxLabel = ({ label, handleDocumentChange, documentType }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    handleDocumentChange(isChecked, documentType);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        sx={{ margin: "0px", marginTop: "-12px", marginLeft: "-12px" }}
      />
      <span style={{ marginLeft: "6px", marginBottom: "12px" }}>{label}</span>
    </div>
  );
};

// Define Window component
const SyncDropDownDialog = ({ onClose, uiLang }) => {
  const [checkedCount, setCheckedCount] = useState(0); // State to track the number of checked checkboxes
  const [NotificationState, setNotificationState] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [selectedTypeOfDocuments, setselectedTypeOfDocuments] = useState([
    "partner",
    "document_series",
    "gl_account",
    "gl_account_configuration",
  ]);
  const [startingDate, setStartingDate] = useState(new Date()); // Initialize startingDate with current date
  const [endingDate, setEndingDate] = useState(new Date()); // Initialize endingDate with current date

  useEffect(() => {
    if (endingDate < startingDate) {
      setNotificationMessage(
        "Error! Starting date should be before ending date"
      );
      setNotificationState(true);
      const timeoutId = setTimeout(() => {
        setNotificationState(false);
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [endingDate, startingDate]);

  const handleDocumentChange = (isChecked, documentTypes) => {
    setselectedTypeOfDocuments((prevSelected) => {
      if (isChecked) {
        setCheckedCount((prevCount) => prevCount + 1);
        // Add document type to selectedTypeOfDocuments
        return [...prevSelected, ...documentTypes];
      } else {
        setCheckedCount((prevCount) => prevCount - 1);
        // Remove document type  from selectedTypeOfDocuments
        return prevSelected.filter((item) => !documentTypes.includes(item));
      }
    });
  };

  const onSyncButton = (event) => {
    setNotificationMessage(uiText.ui_erp_synchronization_start[uiLang]);
    setNotificationState(true);
    setTimeout(() => setNotificationState(false), 4000);
    triggerSynchronizatioByDatesBackground(
      organization_id,
      formatDateToDatabaseDate(startingDate),
      formatDateToDatabaseDate(endingDate),
      selectedTypeOfDocuments
    )
      .then((response) => {})
      .catch(() => {
        setNotificationMessage("Error!");
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };

  return (
    <>
      <Paper
        style={{
          width: "382px", // 382px = Calendar(2*155px) + SpacingAround(3*8px)
          height: "248px", // Around 210px is the total height of the paper without checkboes and:
          //At 223 Sync Button touches the bottom of the paper. So at 223 + 24 = 248px we have the base margin of 24
          //So the height should be 223 + 24px which is the base margin for all the paper
          padding: "20px",
          position: "absolute",
          top: "70px",
          left: "-170px",
          zIndex: "999",
        }}
      >
        {/* Close button */}
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "-7px", right: "-7px" }} //It is 14x14 So the center is at 7x7
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Grid
          item
          container
          spacing={3}
          display={"flex"}
          direction={"row"}
          flexWrap={"nowrap"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          xs={"auto"}
          style={{ position: "absolute", top: "24px", left: "24px" }}
        >
          <DateRangePicker
            startingDate={startingDate}
            endingDate={endingDate}
            setStartingDate={setStartingDate}
            setEndingDate={setEndingDate}
            startLabel={uiText.ui_from[uiLang]}
            endLabel={uiText.ui_to[uiLang]}
          />
        </Grid>
        <Grid
          item
          container
          spacing={1} // Adjust the spacing value as needed
          display={"flex"}
          direction={"column"}
          flexWrap={"nowrap"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          xs={"auto"}
          style={{
            position: "absolute",
            top: "104px",
            left: "25px", //It should be 24px like the rest but because the lines of the square are bold it tricks you into seeing an unsymmetry
          }}
        >
          <Grid item>
            <CheckBoxLabel
              label={uiText.checkBox_documents[uiLang]}
              handleDocumentChange={handleDocumentChange}
              //selectedTypeOfDocuments={selectedTypeOfDocuments}
              //decideDocumentType={uiText.checkBox_documents[uiLang]}
              documentType={[
                "document",
                "document_correlated",
                "document_detail",
              ]}
            />
          </Grid>
          <Grid item>
            <CheckBoxLabel
              label={uiText.checkBox_journal_entries[uiLang]}
              handleDocumentChange={handleDocumentChange}
              documentType={["stage_row"]}
              //selectedTypeOfDocuments={selectedTypeOfDocuments}
            />
          </Grid>
        </Grid>
        <Button
          style={{
            margin: "0rem 0.3rem",
            top: "166px", //At 142px the button touches the bottom of the box. So at 142 + 24 is where it shoud be.
            left: "72px",
          }} //at right:25px it touches the left border. The button is 195px long so the center is 195/2 = 97.5px(97px)
          //so left should be at 97 - 25 = 72 px so that the button is in the center

          onClick={() => onSyncButton(uiLang, organization_id)}
          variant="contained"
          disabled={checkedCount === 0}
        >
          <BsArrowRepeat style={{ marginRight: 10 }} />
          {uiText.refreshErpTooltip[uiLang]}
        </Button>
      </Paper>

      <Snackbar
        open={NotificationState}
        autoHideDuration={3600}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setNotificationState(false);
          }}
          variant="filled"
          severity={
            notificationMessage.includes("Error!") ? "warning" : "success"
          }
          sx={{
            width: "100%",
            fontSize: 20,
            fontFamily: "monospace",
          }}
        >
          {notificationMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export { CheckBoxLabel, SyncDropDownDialog };
